<template>
<div ref="screenshot">
    <div class="fw-bold fs-20 lightred--text hover-gradient">
        <!-- <a :href="domainName + '/signals/portfolio'" class="del-underline">
            {{$t("highcharts.ioBotsModelPortfolio")}}
        </a> -->
        {{$t("highcharts.ioBotsModelPortfolio")}}
    </div>
    <div class="fs-24 fw-bold" v-if="responsive == 'PC'">
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6" class="pb-2 pr-5">
                <span :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')" class="d-flex flex-row-reverse">{{performance == 0 ? '--' : (performance > 0 ? '+' + performance.toFixed(2) : performance.toFixed(2)) + '%'}}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6" class="pt-0 pb-5 pr-5">
                <span v-show="!isTakingScreenshot" class="float-right">
                    <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
                    <v-icon size="18" class="lineblue--text mr-1" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
                </span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 font-3x fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
        <div :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{performance == 0 ? '--' : (performance > 0 ? '+' + performance.toFixed(2) : performance.toFixed(2)) + '%'}}</div>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'mobile' || responsive == 'pad'">
        <span class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</span>
        <span v-show="!isTakingScreenshot" class="float-right mr-2">
            <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
            <v-icon size="18" class="lineblue--text mr-1" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
        </span>
    </div>
    <div :id="id" style="height: 360px" v-if="responsive == 'PC'"></div>
    <div :id="id" style="height: 290px" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
    <v-dialog v-model="screenshotDialog" persistent width="450">
      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="black--text">{{$t("common.imageURL")}}</span>
          <v-spacer></v-spacer>
          <v-btn light icon @click="screenshotDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="!uploadBase64Loading" class="mt-3">
            <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
            <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
          </div>
          <div v-show="uploadBase64Loading" class="mt-3 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Noto Sans SC", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import Bus from '@/components/bus/Bus';
import { mapGetters } from "vuex";
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            id: 'portfolioHistoryLine',
            chart: null,
            loading: false,
            performance: 0,
            domainName: config.domainName,
            // 图片弹窗
            screenshotDialog: false,
            // candleChart图片base64地址
            imageBase64Url: null,
            // candleChart图片http地址
            imageHttpUrl: null,
            // 上传图片加载中
            uploadBase64Loading: false,
            // 是否正在截图
            isTakingScreenshot: false,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '', 
                    renderTo: 'portfolioHistoryLine',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickPosition: 'inside',
                    type: 'category',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: false
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 30,
                    labels: {
                        align: "right",
                        x: -3,
                        formatter: function () {
                            return this.value.toFixed(0) + "%";
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '',
                    valueSuffix: '%',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: "RoE",
                    data: [],
                    color: {
                                linearGradient: {
                                  x1: 0,
                                  x2: 0,
                                  y1: 0,
                                  y2: 1
                                },
                                stops: [
                                  [0, '#4CAF50'],
                                  [1, '#FEE108']
                                ]
                              },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Noto Sans SC\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false,
                    href: config.fund,
                    text: 'View on iobots.pro',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -5
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: false,
                    filename: 'RoE',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
        portfolioRoeHistoryChart(val){
            this.getLine();
        }
    },
    created() {
        if(this.responsive == 'mobile'){
            this.option.xAxis.labels.enabled = false;
        }
    },
    mounted() {
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
        this.getLine();
    },
    computed: {
        ...mapGetters(['responsive', 'darkMode']),
        portfolioRoeHistoryChart(){
            return this.$store.getters.portfolioRoeHistoryChart;
        }
    },
    components: {

    },
    props:[],
    methods: {
        getLine(){
            this.option.series[0].data = this.portfolioRoeHistoryChart.data;
            this.option.xAxis.categories = this.portfolioRoeHistoryChart.date;
            if (this.portfolioRoeHistoryChart.data != null) {
                this.performance = this.portfolioRoeHistoryChart.data[this.portfolioRoeHistoryChart.data.length - 1];
            }
            this.renderChart();
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
            this.loading = false;
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
        // 生成图片
          generateImage() {
            let pageYoffset = window.pageYoffset;
            let documentElementScrollTop = document.documentElement.scrollTop;
            let scrollTop = document.body.scrollTop;
            window.pageYoffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            // 正在截图
            this.isTakingScreenshot = true;
            let _this = this;
            // 延时操作，不要问为什么，不延迟不可以
            setTimeout(function() {
              // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
              html2canvas(_this.$refs.screenshot, {
                backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE',
                useCORS: true
              }).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                _this.imageBase64Url = url;
                // 生成截图完毕
                _this.isTakingScreenshot = false;
                window.pageYoffset = pageYoffset;
                document.documentElement.scrollTop = documentElementScrollTop;
                document.body.scrollTop = scrollTop;
                _this.uploadBase64Loading = true;
                _this.screenshotDialog = true;
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                _this.uploadBase64();
              })
            }, 1);
          },
          // 上传base64
          uploadBase64(){
            this.uploadBase64Loading = true;
            let api = config.baseUrl + '/upload/public/base64';
            let param = { base64: this.imageBase64Url };
            this.$http.post(api, param).then(response => {
              let data = response.data;
              this.uploadBase64Loading = false;
              if (data.code == 200) {
                this.imageHttpUrl = data.result;
              } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
              }
            }, error => {
              this.uploadBase64Loading = false;
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 点击下载图片
          saveImage(){
            let a = document.createElement('a');
            // 设置图片地址
            a.href = this.imageBase64Url;
            // 设定下载名称
            a.download = 'ioBots Model Portfolio';
            // 点击触发下载
            a.click();
          },
          // 复制成功回调函数
          copySuccess(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
          },
          // 复制失败回调函数
          copyError(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
          },
          //全屏
          toggleFullscreen() {
            if (this.chart.fullscreen) {
              this.chart.fullscreen.toggle();
            }
          },
    }
}
</script>
